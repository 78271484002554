"use strict";
(function($){

    /**
     * @namespace $.planactor
     * @memberof $
     * @requires planactor
     */
    if (!$.planactor) $.planactor = new Object();

    /**
     * @namespace $
     * @extends jQuery
     * @requires planactor
     */
    $.extend({
        /**
         * 크로스브라우저 북마크 저장 호출
         * @memberof $
         * @function
         * @example
         * $.bookmark('사이트명','사이트 경로');
         * @requires cssua
         * @param {string} title 사이트명
         * @param {string} url 사이트 경로
         * @returns void
         */
        bookmark : function(title, href){
            if (cssua.ua.ie){
                window.external.AddFavorite(href,title);
            } else if (cssua.ua.firefox) {
                window.sidebar.addPanel(href,title,"");
            } else if (css.ua.opera) {
                var elem = document.createElement('a');
                elem.setAttribute('href',href);
                elem.setAttribute('title',title);
                elem.setAttribute('rel','sidebar');
                elem.click();
            } else {
                // edge or chrome
                // alert('Press Ctrl+D to bookmark (Command+D for mac)');
                alert('현재 페이지를 즐겨찾기 하실려면 단축키 CTRL+D를 눌러주세요!\n\n※ MAC OS는 Command+D를 눌러주세요.');
            }
        },
        /**
         * 윈도우 window.open 호출
         * @memberof $
         * @function
         * @example
         * $.windowopen({
         *     url : '../popup.php',
         *     name : 'test',
         *     width: 500,
         *     height: 500
         * });
         * @param {object} options {
                url     : 'url',
                name    : 'window name',
                method  : 'get',
                data    : {},
                width   : 200,
                height  : 200,
                left    : null,
                top     : null,
                channelmode : 'no', // IE only
                fullscreen  : 'no', // IE only
                location    : 'no', // Opera only
                menubar     : 'no',
                resizable   : 'no', // IE only
                scrollbars  : 'no', // IE, Firefox & Opera only
                status      : 'no',
                titlebar    : 'no',
                toolbar     : 'no'  // IE and Firefox only
            }
         * @returns {window} 팝업 window object
         */
        windowopen : function(options) {
            options = $.extend({
                url     : 'about:blank',
                /*
                _blank  - URL is loaded into a new window. This is default
                _parent - URL is loaded into the parent frame
                _self   - URL replaces the current page
                _top    - URL replaces any framesets that may be loaded
                name    - The name of the window
                */
                name    : '_blank',
                method  : 'get',
                data    : {},
                width   : 200,
                height  : 200,
                left    : null,
                top     : null,
                channelmode : 'no', // IE only
                fullscreen  : 'no', // IE only
                location    : 'no', // Opera only
                menubar     : 'no',
                resizable   : 'no', // IE only
                scrollbars  : 'no', // IE, Firefox & Opera only
                status      : 'no',
                titlebar    : 'no',
                toolbar     : 'no'  // IE and Firefox only
            }, options);
            var url = options.url, name = options.name, data = options.data;
            delete options.url; delete options.name; delete options.data;
            if (null == options.left)   options.left    = (screen.width-Number(options.width))/2;
            if (null == options.top)    options.top     = (screen.height-Number(options.height))/2;
            var specs = [];
            $.each(options, function(k,v){
                specs.push(k+'='+v);
            });

            if ('post' == options.method && !$.isEmptyObject(data)){
                var openwin = window.open('about:blank',name,specs.join(','));
                var $postform = $(document.body).find('#postform');
                if (0 == $postform.length){
                    var $form = $(document.createElement('form')).attr({
                        'id' : 'postform',
                        'name' : 'postform',
                        'action': url,
                        'method': 'post'
                    });
                    $(document.body).append($form);
                }
                $form.find('input').remove();
                $form.attr('target', name);
                $.each(data, function(k,v){
                    var $input = $(document.createElement('input')).attr({
                        'type': 'hidden',
                        'name': k,
                        'value':  v
                    });
                    $form.append($input);
                });
                $form.submit();
            }else{
                var openwin = window.open(url,name,specs.join(','));
            }
            if (openwin == null){
                // 팝업 실패 시 오류처리
                alert('팝업 창을 활성화 해주세요.');
            }
            return openwin;
        }
    });

    /**
     * @namespace $.fn
     * @memberOf $
     * @extends jQuery.fn
     * @requires planactor
     */
    $.fn.extend({
        /**
         * callback return이 하나라도 true면 true를 반환하며 순환을 즉시 중지한다.
         * (나머지 false 반환)
         * @memberOf $.fn
         * @function
         * @example
         * var bool = $('.class').any(function(index, element){
         *     return (3 == index);
         * });
         * console.log(bool);
         * @param {function} callback 콜백함수(index, element)
         * @this element
         * @returns {boolean}
         */
        any : function(callback){
            if (!planactor.isFunction(callback)) {
                throw new TypeError('$.fn.any: callback must be a function');
            }
            var length = this.length;
                for(var i=0; i<length; ++i){
                    if (true === callback.call(this[i], i, this[i])){
                        return true;
                    }
                }
            return false;
        },
        /**
         * callback return이 하나라도 false면 false를 반환하며 순환을 즉시 중지한다.
         * (전체 true일 경우 true를 반환)
         * @memberof $.fn
         * @function
         * @example
         * var bool = $('.class').all(function(index, element){
         *     return (index == 3);
         * });
         * console.log(bool);
         * @param {function} callback 콜백함수(index, element)
         * @this element
         * @returns {boolean}
         */
        all : function(callback){
            if (!planactor.isFunction(callback)) {
                throw new TypeError('$.fn.all: callback must be a function');
            }
            var length = this.length;
            for(var i=0; i<length; ++i){
                if (true !== callback.call(this[i], i, this[i])){
                    return false;
                }
            }
            return true;
        },
        /*
        sortBy : function(callback){
            var sort_array = [];
            if (planactor.isFunction(callback)){
                this.each(function(index){
                    sort_array.push({'element':this,'val':$.proxy(callback,this)(index)});
                });
                var length = this.length, temp;
                for(var i=0; i<length-1; ++i){
                    for(var j=i+1; j<length; ++j){
                        if (sort_array[i].val > sort_array[j].val){
                            temp = sort_array[i], sort_array[i] = sort_array[j], sort_array[j] = temp;
                            temp = this[i], this[i] = this[j], this[j] = temp;
                        }
                    }
                }
                for(var i=0; i<length-1; ++i){
                    $(sort_array[i].element).after(sort_array[i+1].element);
                }
            }
            sort_array = null;
            return this;
        },
        reverseBy : function(){
            var sort_array = [];
            if (planactor.isFunction(callback)){
                this.each(function(index){
                    sort_array.push({'element':this,'val':$.proxy(callback,this)(index)});
                });
                var length = this.length, temp;
                for(var i=0; i<length-1; ++i){
                    for(var j=i+1; j<length; ++j){
                        if (sort_array[i].val < sort_array[j].val){
                            temp = sort_array[i], sort_array[i] = sort_array[j], sort_array[j] = temp;
                            temp = this[i], this[i] = this[j], this[j] = temp;
                        }
                    }
                }
                for(var i=0; i<length-1; ++i){
                    $(sort_array[i].element).after(sort_array[i+1].element);
                }
            }
            return this;
        },
        detect : function(callback){
            var element = undefined;
            if (planactor.isFunction(callback)){
                this.any(function(index){
                    if (undefined === element && true === callback.call(this, index, this)){
                        element = this;
                        return true;
                    }
                    return false;
                });
            } else {
                this.any(function(index){
//                    if (undefined === element && this === $(callback).get(0)){
                    if (undefined === element && $(this).is(callback)){
                        element = this;
                        return true;
                    }
                    return false;
                });
            }
            return $(element);
        },
        */
        /**
         * 포함된 모든 element와 그 부모 element들이 display block인 상태일 경우 return true를 반환.
         * 실제 화면에 출력되고 있는지 유무 판단
         * @memberof $.fn
         * @function
         * @example
         * var bool = $('#identifier').isDisplay();
         * console.log(bool);
         * @returns {boolean}
         */
        isDisplay : function(){
            return this.all(function(){
                return planactor.isDisplay(this);
            });
        },
        /**
         * jQuery serializeArray에 file 객체를 포함함..
         * @memberof $.fn
         * @function
         * @see jQuery.serializeArray()
         * @return {array}
         */
        serializeToArray : function(){
            var rCRLF = /\r?\n/g,
            rinput = /^(?:color|date|datetime|datetime-local|email|hidden|month|number|password|range|search|tel|text|time|url|week|file)$/i,
            rselectTextarea = /^(?:select|textarea)/i;

            return this.map(function(){
                return this.elements
                    ? $.makeArray( this.elements )
                    : ($(this).find(':input').length > 0
                        ? $(this).find(':input').get()
                        : this);
            })
            .filter(function(){
                return this.name && !this.disabled &&
                    ( this.checked || rselectTextarea.test( this.nodeName ) || rinput.test( this.type ) );
            })
            .map(function( i, elem ){
                var val = $( this ).val();
                return planactor.isEmpty(val) ?
                    null :
                    $.isArray( val ) ?
                        $.map( val, function( val, i ){
                            return { name: elem.name, value: val.replace( rCRLF, "\r\n" ) };
                        }) : { name: elem.name, value: val.replace( rCRLF, "\r\n" ) };
            }).get();
        },
        /**
         * element value를 반환한다. radio, checkbox, text 등
         * @memberof $.fn
         * @function
         * @return {array|string}
         * @example
         * $('.radio').getVal();
         */
        getVal : function(array){
           var values = this.serializeToArray().map(function(element){
               return element.value;
           }).compact();
           if (true === array){
               return values;
           } else {
               return (values.length > 1) ? values : values[0];
           }
        },
        /**
         * 체크박스 toggle
         * @memberof $.fn
         * @function
         * @param {string|element} selector 타겟이 되는 checkbox elements
         * @returns $.fn
         * @example
         * $('#checkedAll').checkboxToggle('.targetCheckbox');
         */
        checkboxToggle : function(selector){
            this.each(function(){
                $(this).is(':checked') ? $(selector).prop('checked',true) : $(selector).prop('checked',false);
            });
        },
        /**
         * 체크박스 체크된 수를 리턴한다.
         * @memberof $.fn
         * @function
         * @returns {number} 체크박스에 체크된 count 반환
         * @example
         * $('.ckeckbox').checkedCount()
         */
        checkedCount : function(){
            return this.filter(function(){
                return this.checked;
            }).length;
        }
    });

    /**
     * new $.planactor.validator(form, {});
     * @memberof $.planactor
     * @class
     * @param {string|element} selector
     * @param {object} options 옵션 {@link $.planactor.validator.defaultOptions} 참고
     * @returns class
     * @example
     * new $.planactor.validator('#regform');
     */
    $.planactor.validator = function(form, options){

        var self = this;
        self.form = form;
        self.$form = $(form);

        self.$form.data('planactor.validator', self);
        self.$elements = null;

        self.init = function(){
            self.ajaxOptions = $.extend({
                url     : null,
                type    : 'post',
                success : $.noop,
                async   : true,
                data    : {},
                error   : function(jqXHR, textStatus, errorThrown){
                    alert('ajax request error!');
                }
            }, $.planactor.validator.defaultOptions.ajaxOptions, (options)?options.ajaxOptions:{});
            delete $.planactor.validator.defaultOptions.ajaxOptions;
            if (options) delete options.ajaxOptions;

            self.options = $.extend({}, $.planactor.validator.defaultOptions, options);

            self.$elements = self.$form.find('input,select,textarea').filter(function(index){
                // autocomplete=no
                var $this = $(this);
                if ($this.is(':text')) {
                    $this.attr('autocomplete','off');
                }
                return (this.name && !['submit','reset','image'].includes(this.type.toLowerCase()));
            });

            // 실시간 데이터 변경
            if (self.options.changer){
                self.$elements.each(function(){
                    self.changer(this);
                });
            }

            // 실시간 검증
            if (self.options.realtime) self.onRealtime();
            // form 동작
            if (self.$form.is('form')){
                self.$form.on('submit', function(e){
                    if (self.onSubmit(e)){
                        // ajax
                        if (false === self.options.submit) {
                            e.preventDefault();
                            // Ajax Request URL 설정
                            if (planactor.isEmpty(self.ajaxOptions.url)){
                                self.ajaxOptions.url = (!self.$form.attr('action')) ? location.href : self.$form.attr('action');
                            }
                            self.onAjax();
                        }
                        // submit
                    } else {
                        e.preventDefault();
                    }
                });
                self.$form.on('reset', function(e){
                    self.reset();
                });
            }

            // button 동작
            if (self.options.button){
                var $button = $(self.options.button);
                if ($button.length > 0){
                    if (self.$form.is('form')){
                        $button.on('click', function(e){
                            self.$form.trigger('submit');
                        });
                    } else {
                        // Button 사용 시 Request는 반드시 세팅되어야 함..
                        // if (planactor.isEmpty(self.ajaxOptions.url)) throw 'options.button when using the options.request must be set.';
                        // Ajax Request URL 설정
                        if (planactor.isEmpty(self.ajaxOptions.url)){
                            self.ajaxOptions.url = (!self.$form.attr('action')) ? location.href : self.$form.attr('action');
                        }
                        // element enter event
                        self.$form.on('keyup', function(e){
                            // enter
                            if (13 == e.keyCode){
                                $button.trigger('click');
                            }
                        });
                        $button.on('click', function(e){
                            if (self.onSubmit(e)){
                                self.onAjax();
                            }
                        });
                    }
                }
            }
        };

        /**
         * form data object 반환
         */
        self.params = function(){
            return $.extend({}, self.ajaxOptions.data, self.$form.serializeJson());
        };

        /**
         * Ajax 사용 시
         */
        self.onAjax = function(){
            // ajax 중지
            if (!self.options.ajax) return self;
            // Parameters 설정
            var ajaxOptions = $.extend({}, self.ajaxOptions, {
                data : self.params(),
                // ajax 통신
                success : function(data){
                    if (self.options.indicator){
                        self.$form.data('planactor.indicator').remove();
                    }
                    self.ajaxOptions.success(data);
                }
            });
            $.ajax(ajaxOptions);
            return self;
        };

        /**
         * 실시간 검증
         */
        self.onRealtime = function(){
            /**
             * 첫번째 오류발경 시 stop
             */
            self.$elements.each(function(){
                $(this).on('focus', function(e){
                    // 포커스 위치 시 검증결과 초기화
                    self.reset(this);
                });
                $(this).on('blur', function(e){
                    var element = this;
                    // submit이 일어날 시 clearTimeout() 됨...
                    self.realtimeHandler = setTimeout(function(){
                        if (!self.isValid(element, true)){}
                    }, 200);
                });
            });
            return self;
        };

        /**
         * 검증모듈 시작
         */
        self.onSubmit = function(e){
            // form enter submit 시 현재 선택된 element blur trigger (changer 기능활성)
            if (self.focusElement) {
                $(self.focusElement).trigger('blur');
            }
            // 실시간 검증 실행 중일 경우 실시간 검증 실행을 취소시킴 (중복에러 방지)
            clearTimeout(self.realtimeHandler);
            // 초기화
            self.reset();

            /**
             * 첫번째 오류발경 시 stop
             */
            var result = false;
            if (self.options.stopOnFirst){
                result = self.$elements.all(function(){
                    return self.isValid(this);
                });
            } else {
                result = self.$elements.map(function(){
                    return self.isValid(this);
                }).all();
            }
            // 검증 실패 시 focus 지정 (realtime=true 일경우 에러 출력 시 focus가 가면 에러가 반복되는 현상발생)
            if (false === result && true === self.options.focusOnError && !self.options.realtime){
                var $element = self.$elements.find('.'+self.options.classes.failure);
                $element.is(':text,textarea') ? $element.select() : $element.focus();
            }

            if (result){
                if (planactor.isFunction(self.options.success)){
                    result = (false === self.options.success(e, self))?false:true;
                }
            } else {
                if (planactor.isFunction(self.options.failure)){
                    self.options.failure(e, self);
                }
            }
            if (planactor.isFunction(self.options.onFormComplete)){
                result = (false === self.options.onFormComplete(e, self, result))?false:true;
            }

            // 검증 성공 시 indicator
            if (true === result){
                if (self.options.indicator && !self.$form.data('planactor.indicator')){
                    self.$form.planactor_indicator({
                        'className' : 'bigBlackIndicator'
                    });
                }
            }
            return result;
        };

        /**
         * form submit 전환 (폼을 강제적으로 submit 시킨다)
         */
        self.submit = function(){
            self.$form.off("submit");

            if (false === self.options.submit) {
                // Ajax Request URL 설정
                if (planactor.isEmpty(self.ajaxOptions.url)){
                    self.ajaxOptions.url = (!self.$form.attr('action')) ? location.href : self.$form.attr('action');
                }
                self.onAjax();
            }
            else {
                self.$form.submit();
            }
            return self;
        };

        /**
         * ger indicator
         */
        self.indicator = (function(){
            // get indicator object
            function get(){
                if (self.options.indicator && self.$form.data('planactor.indicator')){
                    return self.$form.data('planactor.indicator');
                }
                return null;
            };
            // remove indicator object
            function remove(){
                var $indicator = get();
                if ($indicator){
                    $indicator.remove();
                }
            }
            return {
                'get' : get,
                'remove' : remove
            };
        })();

        /**
         * values changer
         */
        self.changer = function(element){
            var $element = $(element);
            // class=empty || disabled=true || element가 hide 이면 검증 pass (class가 validate-hidden일 경우 display none이여도 validate에 포함한다.)
            if (planactor.isEmpty($element.attr('class')) || true === element.disabled || (!$element.isDisplay() && !$element.hasClass('validate-force'))) return true;
            var classes = $element.attr('class').match(/([a-zA-Z0-9\-]+\s*(\(.*\))?)/ig).findAll(function(obj){
                return !planactor.isEmpty(obj);
            }).map(function(obj){
                // 공백제거
                return obj.replace(/\s+/g,'');
            });
            if (classes){
                // 검증
                return classes.each(function(clss, i){
                    var splitClass = clss.replace(/["']/g,'').match(/([a-zA-Z0-9\-]+)\s*(\((.*)\))?/);
                    var args = (splitClass[3]) ? splitClass[3].split(',') : [];
                    // class 정리
                    var rule = $.planactor.validator.changer.get(splitClass[1]);
                    if (planactor.isObject(rule)){
                        $.each(rule, function(k, v){
                            $element.on(k, function(e){
                                // enter submit 시 focus된 element 강제 blur하기 위함
                                if ('focus' == k){
                                    self.focusElement = element;
                                } else if ('blur' == k){
                                    self.focusElement = null;
                                }
                                if (planactor.isEmpty($(this).getVal())) return true;
                                if (!v.apply(element,[self, args, e])){
                                    return false;
                                }
                            });
                        });
                    }
                });
            }
        };

        /**
         * 객체 검증
         */
        self.isValid = function(element, realtime){
            var $element = $(element);

            /**
             * 다중선택 등의 element들에 대해 하나씩만 체크함 (중복 체크 방지)
             */
            switch (element.type){
                case 'select-multiple':
                    var $elements = self.$form.find('select[name="'+element.name+'"]');
                    $element = $elements.eq(0);
                    break;
                case 'radio':
                    var $elements = self.$form.find('input:radio[name="'+element.name+'"]');
                    $element = $elements.eq(0);
                    break;
                case 'checkbox':
                    var $elements = self.$form.find('input:checkbox[name="'+element.name+'"]');
                    $element = $elements.eq(0);
                    break;
                default:
            }

            // 이미 체크된 항목에 대해서는 체크하지 않는다.
            if ($element.hasClass(self.options.classes.duplication)) return true;

            // 검증이 실시된 element임을 표시
            if ($elements) $elements.addClass(self.options.classes.duplication);
            else $element.addClass(self.options.classes.duplication);

            // class=empty || disabled=true || element가 hide 이면 검증 pass (class가 validate-hidden일 경우 display none이여도 validate에 포함한다.)
            if (planactor.isEmpty($element.attr('class')) || true === element.disabled || (!$element.isDisplay() && !$element.hasClass('validate-force'))) return true;
            var classes = $element.attr('class').match(/([a-zA-Z0-9\-]+\s*(\(.*\))?)/ig).findAll(function(obj){
                return !planactor.isEmpty(obj);
            }).map(function(obj){
                // 공백제거
                return obj.replace(/\s+/g,'');
            });

            if (classes){
                // 검증
                return classes.all(function(clss, i){
                    var splitClass = clss.replace(/["']/g,'').match(/([a-zA-Z0-9\-]+)\s*(\((.*)\))?/);
                    var args = (splitClass[3]) ? splitClass[3].split(',') : [];
                    // class 정리
                    var rule = $.planactor.validator.rule.get(splitClass[1]);

                    if (planactor.isFunction(rule)){
                        // 실시간 및 submit alert 모듈 구분
                        var malert = (!realtime) ? $.planactor.validator.alert.get(self.options.onAlert) : $.planactor.validator.alert.get(self.options.onRealtimeAlert);
                        if (!malert) $.planactor.validator.alert.get('alert');
                        // 데이터 검증
                        if (!rule.apply(element,[self, args, $elements])){
                            // title 설정 시 에러문구 title로 변경
                            if (self.options.useTitle && !planactor.isEmpty($(element).attr('errormsg'))){
                                $(element).data('validate-errormsg', $(element).attr('errormsg'));
                            }
                            // failure 호출
                            malert.onFailure.apply(element, [self, $elements]);
                            return false;
                        }
                        // success 호출
                        malert.onSuccess.apply(element, [self, $elements]);
                    }
                    return true;
                });
            }
            return true;
        };

        /**
         * 초기화
         */
        self.reset = function(element){
            if (element){
                var $element = $(element);
                $element.removeClass(self.options.classes.duplication);
                var alert = $.planactor.validator.alert.get(self.options.onAlert);
                alert.onReset.apply(element, [self, self.options]);
            } else {
                if (self.$elements){
                    // 중복검증 회피 class 초기화
                    self.$elements.removeClass(self.options.classes.duplication);
                    // element class 초기화
                    var alert = $.planactor.validator.alert.get(self.options.onAlert);
                    self.$elements.each(function(){
                        alert.onReset.apply(this, [self, self.options]);
                    });
                    self.indicator.remove();
                }
            }
        };
        self.init();
    };
    /**
     * $.planactor.validator 사용 시 기본 옵션
     * @memberof $.planactor.validator
     * @static
     * @example
$.extend($.planactor.validator.defaultOptions, {
    realtime    : true,        // 실시간 검증
    useTitle    : true,         // 에러메시지를 errormsg 속성으로 변경
    onAlert     : 'alert',      // 에러발생 시 alert 모듈 (noty 등)
    onRealtimeAlert : 'border',
    submit      : true,         // form일경우 submit 유무
    changer     : true,
    stopOnFirst : true,         // 첫 에러발생 stop
    focusOnError: true,         // 에러발생 시 포커스
    indicator   : false,        // indicator 실행여부
    button      : null,
    ajax        : true,         // ajax 실행여부
    ajaxOptions : {},           // ajax 실행 시 옵션설정
    classes     : {
        duplication : 'planactor-validate-duplication',
        success     : 'planactor-validate-success',
        failure     : 'planactor-validate-failure'
    },
    success     : null,
    failure     : null,
    onFormComplete : null
});
     */
    $.planactor.validator.defaultOptions = {
        realtime    : true,        // 실시간 검증
        useTitle    : true,         // 에러메시지를 errormsg 속성으로 변경
        onAlert     : 'alert',      // 에러발생 시 alert 모듈 (noty 등)
        onRealtimeAlert : 'border',
        submit      : true,         // form일경우 submit 유무
        changer     : true,
        stopOnFirst : true,         // 첫 에러발생 stop
        focusOnError: true,         // 에러발생 시 포커스
        indicator   : false,        // indicator 실행여부
        button      : null,
        ajax        : true,         // ajax 실행여부
        ajaxOptions : {},           // ajax 실행 시 옵션설정
        classes     : {
            duplication : 'planactor-validate-duplication',
            success     : 'planactor-validate-success',
            failure     : 'planactor-validate-failure'
        },
        success     : null,
        failure     : null,
        onFormComplete : null
    };

    /**
     * $.planactor.validator 사용 시 실시간 검증 모듈
     * @memberof $.planactor.validator
     * @namespace $.planactor.validator.changer
     * @static
     */
    $.planactor.validator.changer = {
        rules : {},
        /**
         * @memberof $.planactor.validator.changer
         * @function
         * @param {array|string|function} arguments 다중등록 시 array, 단일등록 시 string(classname),function
         * @example
// 다중등록방식
$.planactor.validator.changer.add([
    ['validate-phone', {
        'focus': function(self, args, e){
            var $element = $(this);
            $element.val($element.val().replace(/-/g,''));
        },
        'blur' : function(self, args, e){
            var $element = $(this);
            $element.val($element.val().replace(/-/g,'').replace(
                /^(02|0[3-8][0-5]|01[016-9])-?([0-9]{3,4})-?([0-9]{4})$/, '$1-$2-$3'
            ).replace(
                /^(1544|1566|1577|1588|1599|1600|1644|1688)-?([0-9]{4})$/, '$1-$2'
            ));
        }
    }],
    ...
]);
// 단일등록방식
$.planactor.validator.changer.add('validate-phone', {
    'focus': function(self, args, e){
        var $element = $(this);
        $element.val($element.val().replace(/-/g,''));
    },
    'blur' : function(self, args, e){
        var $element = $(this);
        $element.val($element.val().replace(/-/g,'').replace(
            /^(02|0[3-8][0-5]|01[016-9])-?([0-9]{3,4})-?([0-9]{4})$/, '$1-$2-$3'
        ).replace(
            /^(1544|1566|1577|1588|1599|1600|1644|1688)-?([0-9]{4})$/, '$1-$2'
        ));
    }
});
         */
        add : function(){
            switch(arguments.length){
                case 1:
                    if (planactor.isArray(arguments[0])){
                        arguments[0].each(function(v, i){
                            if (planactor.isString(v[0]) && planactor.isObject(v[1])){
                                this.rules[v[0]] = v[1];
                            }
                        }, this);
                    }
                    break;
                case 2:
                    if (planactor.isString(arguments[0]) && planactor.isObject(arguments[1])){
                        this.rules[arguments[0]] = arguments[1];
                    }
                    break;
                default:
            }
            return this;
        },
        /**
         * @memberof $.planactor.validator.changer
         * @function
         * @param {string} classname
         * @returns {function}
         */
        get : function(alert){
            return this.rules[alert];
        }
    };
    $.planactor.validator.changer.add([
        ['validate-phone', {
            'focus': function(self, args, e){
                var $element = $(this);
                $element.val($element.val().replace(/-/g,''));
            },
            'blur' : function(self, args, e){
                var $element = $(this);
                $element.val($element.val().replace(/-/g,'').replace(
                    /^(02|0[3-8][0-5]|01[016-9])-?([0-9]{3,4})-?([0-9]{4})$/, '$1-$2-$3'
                ).replace(
                    /^(1544|1566|1577|1588|1599|1600|1644|1688)-?([0-9]{4})$/, '$1-$2'
                ));
            }
        }],
        ['validate-mobile', {
            'focus' : function(self, args, e){
                var $element = $(this);
                $element.val($element.val().replace(/-/g,''));
            },
            'blur' : function(self, args, e){
                var $element = $(this);
                $element.val($element.val().replace(/-/g,'').replace(
                    /^(050[256]|01[016-9])-?([1-9]{1}[0-9]{2,3})-?([0-9]{4})$/, '$1-$2-$3'
                ));
            }
        }],
        ['validate-fax', {
            'focus' : function(self, args, e){
                var $element = $(this);
                $element.val($element.val().replace(/-/g,''));
            },
            'blur' : function(self, args, e){
                var $element = $(this);
                $element.val($element.val().replace(/-/g,'').replace(
                    /^(02|0[3-8][0-5]|01[016-9])-?([0-9]{3,4})-?([0-9]{4})$/, '$1-$2-$3'
                ).replace(
                    /^(1544|1566|1577|1588|1599|1600|1644|1688)-?([0-9]{4})$/, '$1-$2'
                ));
            }
        }],
        ['validate-rrn', {
            'focus' : function(self, args, e){
                var $element = $(this);
                $element.val($element.val().replace(/-/g,''));
            },
            'blur' : function(self, args, e){
                var $element = $(this);
                $element.val($element.val().replace(
                    planactor.regexp.rrn, '$1-$2'
                ));
            }
        }],
        ['validate-brn', {
            'focus' : function(self, args, e){
                var $element = $(this);
                $element.val($element.val().replace(/-/g,''));
            },
            'blur' : function(self, args, e){
                var $element = $(this);
                $element.val($element.val().replace(
                    planactor.regexp.brn, '$1-$2-$3'
                ));
            }
        }],
        ['validate-hostname', {
            'blur' : function(self, args, e){
                var $element = $(this);
                $element.val(planactor.toHostname($element.val()));
            }
        }],
        ['validate-date', {
            'blur' : function(self, args, e){
                var $element = $(this);
                $element.val($.toDate($element.val()));
            }
        }],
        ['validate-currency', {
            'focus' : function(self, args, e) {
                var $element = $(this);
                $element.val(String($element.val()).replace(/,/g,''));
            },
            'blur' : function(self, args, e) {
                var $element = $(this);
                var value = $element.val();
                value = value.pgsub(',','');
                if (planactor.isCurrency(value)){
                    var regexp = /(^[+-]?\d+)(\d{3})/;
                    while(regexp.test(value)){
                        value = value.psub(regexp,'$1,$2');
                    }
                    $element.val(value);
                }
            }
        }],
        ['validate-currency-dollar', {
            'focus' : function(self, args, e){
                var $element = $(this);
                $element.val($element.val().replace(/,/g,''));
            },
            'blur' : function(self, args, e){
                var $element = $(this);
                var value = $element.val();
                value = value.replace(/,/g,'');
                if (planactor.isCurrencyUSD(value)) {
                    var regexp = /(^[+-]?[\$]?\d+)(\d{3})/;
                    while(regexp.test(value)) {
                        value = value.psub(regexp,'$1,$2');
                    }
                    $element.val(value);
                }
            }
        }],
        // 대한민국(원)
        ['validate-currency-KRW', {
            'focus' : function(self, args, e) {
                var $element = $(this);
                $element.val(String($element.val()).replace(/,/g,''));
            },
            'blur' : function(self, args, e) {
                var $element = $(this);
                var value = $element.val();
                value = value.pgsub(',','');
                if (planactor.isCurrency(value)){
                    var regexp = /(^[+-]?\d+)(\d{3})/;
                    while(regexp.test(value)){
                        value = value.psub(regexp,'$1,$2');
                    }
                    $element.val(value);
                }
            }
        }],
        // 미국(달러)
        ['validate-currency-USD', {
            'focus' : function(self, args, e){
                var $element = $(this);
                $element.val($element.val().replace(/,/g,''));
            },
            'blur' : function(self, args, e){
                var $element = $(this);
                var value = $element.val();
                value = value.replace(/,/g,'');
                if (planactor.isCurrencyUSD(value)) {
                    var regexp = /(^[+-]?[\$]?\d+)(\d{3})/;
                    while(regexp.test(value)) {
                        value = value.psub(regexp,'$1,$2');
                    }
                    $element.val(value);
                }
            }
        }],
        // 중국(위안)
        ['validate-currency-CNY', {
            'focus' : function(self, args, e){
                var $element = $(this);
                $element.val($element.val().replace(/,/g,''));
            },
            'blur' : function(self, args, e){
                var $element = $(this);
                var value = $element.val();
                value = value.replace(/,/g,'');
                if (planactor.isCurrencyUSD(value)) {
                    var regexp = /(^[+-]?[\$]?\d+)(\d{3})/;
                    while(regexp.test(value)) {
                        value = value.psub(regexp,'$1,$2');
                    }
                    $element.val(value);
                }
            }
        }]
    ]);

    /**
     * $.fn.planactor_validator 에러발생 시 alert 모듈
     * @memberof $.planactor.validator
     * @namespace $.planactor.validator.alert
     */
    $.planactor.validator.alert = {
        rules : {},
        /**
         * @memberof $.planactor.validator.alert
         * @function
         * @param {array|string|function} arguments 다중등록 시 array, 단일등록 시 string(classname),function
         */
        add : function(){
            switch(arguments.length){
                case 1:
                    if (planactor.isArray(arguments[0])){
                        arguments[0].each(function(v, i){
                            if (planactor.isString(v[0]) && planactor.isObject(v[1])){
                                this.rules[v[0]] = v[1];
                            }
                        },this);
                    }
                    break;
                case 2:
                    if (planactor.isString(arguments[0]) && planactor.isObject(arguments[1])){
                        this.rules[arguments[0]] = arguments[1];
                    }
                    break;
                default:
            }
            return this;
        },
        /**
         * @memberof $.planactor.validator.alert
         * @function
         * @param {string} classname
         * @returns {function}
         */
        get : function(alert){
            return this.rules[alert];
        }
    };
    $.planactor.validator.alert.add([
        ['alert', {
            'onSuccess' : function(self, $elements){
                var $element = $(this);
                $element.removeClass(self.options.classes.failure);
                $element.addClass(self.options.classes.success);
            },
            'onFailure' : function(self, $elements){
                var $element = $(this);
                if (!$element.hasClass(self.options.classes.failure)){
                    if (!planactor.isEmpty($element.data('validate-errormsg'))) {
                        alert($element.data('validate-errormsg'));
                    }
                    $element.removeClass(self.options.classes.success);
                    $element.addClass(self.options.classes.failure);
                }
            },
            'onReset' : function(self){
                var $element = $(this);
                $element.removeClass(self.options.classes.success);
                $element.removeClass(self.options.classes.failure);
            }
        }],
        ['swal', {
            'onSuccess' : function(self, $elements){
                var $element = $(this);
                $element.removeClass(self.options.classes.failure);
                $element.addClass(self.options.classes.success);
            },
            'onFailure' : function(self, $elements){
                var $element = $(this);

                var errormsg = $element.data('validate-errormsg');
                if (planactor.isNotEmpty(errormsg)){

                    swal({
                        title: "",
                        text: errormsg,
                        type: "warning"
                    }, function(){
                        setTimeout(function(){
                            $element.select();
                        },100);
                    });

                    $element.removeClass(self.options.classes.success);
                    $element.addClass(self.options.classes.failure);
                }
            },
            'onReset' : function(self){
                var $element = $(this);
                $element.removeClass(self.options.classes.success);
                $element.removeClass(self.options.classes.failure);
            }
        }],
        ['label', {
            'onSuccess' : function(self, $elements){
                var $element = $(this);
                if ($element.data('error')){
                    $element.data('error').remove();
                }
                $element.removeClass(self.options.classes.failure);
                $element.addClass(self.options.classes.success);
            },
            'onFailure' : function(self, $elements){
                var $element = $(this);
                if ($element.data('validate-errormsg')){
                    var $label = $('<label/>').attr('for',this.id).addClass('planactor-validate-failure-message').text($element.data('validate-errormsg'));
                    switch(this.type){
                        case 'select-multiple':
                            var $parent = $element.parent();
                            while ('BODY' != $parent.get(0).tagName){
                                if ($parent.find('select[name="'+this.name+'"]').length == $elements.length){
                                    $parent.append($label);
                                    break;
                                }
                                $parent = $parent.parent();
                            }
                            break;
                        case 'radio' :
                            var $parent = $element.parent();
                            while ('BODY' != $parent.get(0).tagName){
                                if ($parent.find(':radio[name="'+this.name+'"]').length == $elements.length){
                                    $parent.append($label);
                                    break;
                                }
                                $parent = $parent.parent();
                            }
                            break;
                        case 'checkbox':
                            var $parent = $element.parent();
                            while ('BODY' != $parent.get(0).tagName){
                                if ($parent.find(':checkbox[name="'+this.name+'"]').length == $elements.length){
                                    $parent.append($label);
                                    break;
                                }
                                $parent = $parent.parent();
                            }
                            break;
                        default:
                            $element.after($label);
                    }
                    $element.data('error', $label);
                    $element.removeClass(self.options.classes.success);
                    $element.addClass(self.options.classes.failure);
                }
            },
            'onReset' : function(self){
                var $element = $(this);
                if ($element.data('error')){
                    $element.data('error').remove();
                }
                $element.removeClass(self.options.classes.success);
                $element.removeClass(self.options.classes.failure);
            }
        }],
        ['border', {
            'onSuccess' : function(self, $elements){
                var $element = $(this);
                $element.removeClass(self.options.classes.failure);
                $element.addClass(self.options.classes.success);
            },
            'onFailure' : function(self, $elements){
                var $element = $(this);
                if (!$element.hasClass(self.options.classes.failure)){
                    $element.removeClass(self.options.classes.success);
                    $element.addClass(self.options.classes.failure);
                }
            },
            'onReset' : function(self){
                var $element = $(this);
                $element.removeClass(self.options.classes.success);
                $element.removeClass(self.options.classes.failure);
            }
        }]
    ]);
    /**
     * $.fn.planactor_validator 에러발생 시 alert 모듈
     * @memberof $.planactor.validator
     * @namespace $.planactor.validator.rule
     */
    $.planactor.validator.rule = {
        rules : {},
        /**
         * @memberof $.planactor.validator.rule
         * @function
         * @param {array|string|function} arguments 다중등록 시 array, 단일등록 시 string(classname),function
         * @example
// 다중등록방식
$.planactor.validator.rule.add([
    ['validate-identifier', function(self, args){
        var $element = $(this);

        // input:text 만 검사
        if ($element.is(':text')){
            var val = $element.val();
            if (planactor.isEmpty(val)) return true;
            if (!/^[a-zA-Z]/.test(val)){
                $element.data('validate-errormsg', '아이디는 반드시 영문자로 시작되어야 합니다.');
                return false;
            }
            if (!/^[A-Za-z]{1}[A-Za-z0-9]{5,19}$/.test(val)){
                $element.data('validate-errormsg', '아이디는 6~20자 내의 영문 또는 영문+숫자 조합 형태만 허용합니다.');
                return false;
            }
        }
        return true;
    }],
    ...
]);
// 단일등록방식
$.planactor.validator.rule.add('validate-identifier', function(self, args){
    var $element = $(this);
    // input:text 만 검사
    if ($element.is(':text')){
        var val = $element.val();
        if (planactor.isEmpty(val)) return true;
        if (!/^[a-zA-Z]/.test(val)){
            $element.data('validate-errormsg', '아이디는 반드시 영문자로 시작되어야 합니다.');
            return false;
        }
        if (!/^[A-Za-z]{1}[A-Za-z0-9]{5,19}$/.test(val)){
            $element.data('validate-errormsg', '아이디는 6~20자 내의 영문 또는 영문+숫자 조합 형태만 허용합니다.');
            return false;
        }
    }
    return true;
});
         */
        add : function(){
            switch(arguments.length){
                case 1:
                    if (planactor.isArray(arguments[0])){
                        arguments[0].each(function(v, i){
                            if (planactor.isFunction(v[1])){
                                this.rules[v[0]] = v[1];
                            }
                        },this);
                    }
                    break;
                case 2:
                    if (planactor.isFunction(arguments[1])){
                        this.rules[arguments[0]] = arguments[1];
                    }
                    break;
                default:
            }
            return this;
        },
        /**
         * @memberof $.planactor.validator.rule
         * @function
         * @param {string} classname
         * @returns {function}
         */
        get : function(clss){
            return this.rules[clss];
        }
    };
    $.planactor.validator.rule.add([
        /**
         * @param self
         * @param args
         * @param $elements (select-multiple, radio, checkbox 등 다중 element일경우만 제공)
         * @return boolean
         */
        ['required', function(self, args, $elements){
            var $element = $(this);
            var num = (!args[0]) ? 1 : parseInt(args[0]);
            switch(this.type){
                case 'select-one':
                    if (planactor.isEmpty($element.val())){
                        $element.data('validate-errormsg', '필수 선택 항목입니다.');
                        return false;
                    }
                    break;
                case 'select-multiple':
                    var values = $elements.val();
                    if(planactor.isEmpty(values)){
                        $element.data('validate-errormsg', '필수 선택 항목입니다.');
                        return false;
                    }
                    // 다중선택
                    if (num > 1){
                        if (!planactor.isArray(values) || values.compact(true).length < num){
                            $element.data('validate-errormsg', '필수 다중선택 항목입니다.\n\n'+num+'개이상 선택해주시기 바랍니다.');
                            return false;
                        }
                    }
                    break;
                case 'radio':
                    var values = $elements.getVal();
                    if(planactor.isEmpty(values)){
                        $element.data('validate-errormsg', '필수 선택 항목입니다.');
                        return false;
                    }
                    break;
                case 'checkbox':
                    var values = $elements.getVal();
                    if (planactor.isEmpty(values)) {
                        $element.data('validate-errormsg', '필수 체크 항목입니다.');
                        return false;
                    }
                    if (num > 1) {
                        // 다중선택
                        if (!planactor.isArray(values) || values.compact(true).length < num) {
                            $element.data('validate-errormsg', '필수 다중체크 항목입니다.\n\n'+num+'개이상 체크해주시기 바랍니다.');
                            return false;
                        }
                    }
                    break;
                case 'file':
                    if (planactor.isEmpty($element.val())){
                        $element.data('validate-errormsg', '필수 파일업로드 항목입니다.');
                        return false;
                    }
                    break;
                default:
                    if (planactor.isEmpty($element.val())){
                        $element.data('validate-errormsg', '필수 등록 항목입니다.');
                        return false;
                    }
            }
            return true;
        }],
        ['validate-identifier', function(self, args){
            var $element = $(this);

            // input:text 만 검사
            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!/^[a-zA-Z]/.test(val)){
                    $element.data('validate-errormsg', '아이디는 반드시 영문자로 시작되어야 합니다.');
                    return false;
                }
                if (!/^[A-Za-z]{1}[A-Za-z0-9]{5,19}$/.test(val)){
                    $element.data('validate-errormsg', '아이디는 6~20자 내의 영문 또는 영문+숫자 조합 형태만 허용합니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-password', function(self, args){
            var $element = $(this);

            if ($element.is(':password,:text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;

                // 영문(소문자+대문자)+숫자+특수문자
                if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,20}$/.test(val)){
                    $element.data('validate-errormsg', '비밀번호는 영문(대문자+소문자)+숫자+특수문자를 포함한 6~20자 내의 문자열만 허용합니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-repassword', function(self, args){
            var $element = $(this);
            var $target = (args[0]) ? $(args[0]).eq(0) : self.$form.find('.validate-password').eq(0);

            if ($element.is(':password,:text')){
                var val = $target.val();
                if (planactor.isEmpty(val)) return true;
                if ($element.val() != val){
                    $element.data('validate-errormsg', '비밀번호와 재확인 비밀번호가 일치하지 않습니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-newpassword', function(self, args){
            var $element = $(this);
            min = (!args[0]) ? 6 : args[0], max = (!args[1]) ? 20 : args[1];

            if ($element.is(':password,:text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;

                // 영문(소문자+대문자)+숫자+특수문자
                if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,20}$/.test(val)){
                    $element.data('validate-errormsg', '변경할 비밀번호는 영문(대문자+소문자)+숫자+특수문자를 포함한 6~20자 내의 문자열만 허용합니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-renewpassword', function(self, args){
            var $element = $(this);
            var $target = (args[0]) ? $(args[0]).eq(0) : self.$form.find('.validate-newpassword').eq(0);

            if ($element.is(':password,:text')){
                var val = $target.val();
                if (planactor.isEmpty(val)) return true;
                if ($element.val() != val){
                    $element.data('validate-errormsg', '변경할 비밀번호와 재확인 비밀번호가 일치하지 않습니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-number', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isNumber(val)){
                    $element.data('validate-errormsg', '이 항목은 숫자 입력만 허용합니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-minnumber', function(self, args){
            if (args.length < 1) return true;
            var $element = $(this);
            var min = args[0];

            if ($element.is(':input')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isNumber(val)){
                    $element.data('validate-errormsg', '이 항목은 숫자 입력만 허용합니다.');
                    return false;
                }
                if (Number(val) < Number(min)){
                    $element.data('validate-errormsg', '이 항목은 최소 '+min+' 이상의 숫자 입력만 허용합니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-maxnumber', function(self, args){
            if (args.length < 1) return true;
            var $element = $(this);
            var max = args[0];

            if ($element.is(':input')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isNumber(val)){
                    $element.data('validate-errormsg', '이 항목은 숫자 입력만 허용합니다.');
                    return false;
                }
                if (Number(val) > Number(max)){
                    $element.data('validate-errormsg', '이 항목은 최대 '+max+' 이하의 숫자 입력만 허용합니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-digit', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isDigit(val)){
                    $element.data('validate-errormsg', '이 항목은 숫자(정수) 입력만 허용합니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-alpha', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isAlpha(val)){
                    $element.data('validate-errormsg', '이 항목은 영문(알파벳) 입력만 허용합니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-alnum', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isAlnum(val)){
                    $element.data('validate-errormsg', '이 항목은 영문(알파벳) 또는 영문+숫자 입력만 허용합니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-email', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isEmail(val)){
                    $element.data('validate-errormsg', '이 항목은 이메일(E-mail) 형식만 허용합니다.\n\n유효한 이메일주소를 입력해주세요.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-hostname', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isHostname(val)){
                    $element.data('validate-errormsg', '이 항목은 URL 형식만 허용합니다.\n\n유효한 URL 주소를 입력해주세요.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-date', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isDate(val)){
                    $element.data('validate-errormsg', '유효한 날짜가 아니거나 형식이 유효하지 않습니다.\n\nex) YYYY/mm/dd | YYYY-mm-dd | YYYY.mm.dd');
                    return false;
                }
            }
            return true;
        }],
        ['validate-mobile', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isMobile(val)){
                    $element.data('validate-errormsg', '유효한 휴대폰 번호가 아닙니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-phone', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isPhone(val)){
                    $element.data('validate-errormsg', '유효한 전화번호가 아닙니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-fax', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isPhone(val)){
                    $element.data('validate-errormsg', '유효한 팩스번호가 아닙니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-rrn', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isRrn(val)){
                    $element.data('validate-errormsg', '유효한 주민등록번호가 아닙니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-brn', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isBrn(val)){
                    $element.data('validate-errormsg', '유효한 사업자등록번호가 아닙니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-currency', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isCurrency(val)){
                    $element.data('validate-errormsg', '유효한 통화(화폐) 형태가 아닙니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-currency-dollar', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isCurrencyUSD(val)){
                    $element.data('validate-errormsg', '유효한 달러 통화(화폐) 형태가 아닙니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-currency-yuan', function(self, args){
            var $element = $(this);

            if ($element.is(':text')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!planactor.isCurrencyCNY(val)){
                    $element.data('validate-errormsg', '유효한 위안 통화(화폐) 형태가 아닙니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-minlength', function(self, args){
            if (args.length < 1) return true;
            var $element = $(this);
            var min = args[0];

            if ($element.is(':input')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!val.isLength(min)){
                    $element.data('validate-errormsg', '이 항목은 최소 '+min+'자 이상 길이의 문자열만 허용합니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-maxlength', function(self, args){
            if (args.length < 1) return true;
            var $element = $(this);
            var max = args[0];

            if ($element.is(':input')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!val.isLength(0, max)){
                    $element.data('validate-errormsg', '이 항목은 최대 '+max+'자 이하 길이의 문자열만 허용합니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-range', function(self, args){
            if (args.length < 2) return true;
            var $element = $(this);
            var min = args[0], max = args[1];

            if ($element.is(':input')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!val.isNumber() || !val.isRange(min, max)){
                    $element.data('validate-errormsg', '이 항목은 '+min+' ~ '+max+' 범위의 숫자만 허용합니다.');
                    return false;
                }
            }
            return true;
        }],
        ['validate-length', function(self, args){
            if (args.length < 2) return true;
            var $element = $(this);
            var min = args[0], max = args[1];
            if ($element.is(':input')){
                var val = $element.val();
                if (planactor.isEmpty(val)) return true;
                if (!val.isLength(min, max)){
                    $element.data('validate-errormsg', '이 항목은 '+min+' ~ '+max+'자 내의 문자열만 허용합니다.');
                    return false;
                }
            }
            return true;
        }]
    ]);
    /**
     * From Validator
     * @memberof $.fn
     * @class
     * @param {object} options 옵션 {@link $.planactor.validator.defaultOptions} 참고
     * @returns $.fn
     * @extends $.planactor.validator
     * @example
     * $('#regform').planactor_validator({
     *     onAlert : 'alert',      // 에러발생 시 alert 모듈 (noty 등)
     * });
     */
    $.fn.planactor_validator = function(options){
        return this.each(function(){
            (new $.planactor.validator(this, options));
        });
    };

    /**
     * 인디케이터 클래스
     * new $.planactor.indicator(element, options)
     * @memberof $.planactor
     * @class
     * @param {element} element
     * @param {object} options 옵션 {@link $.planactor.indicator.defaultOptions} 참고
     * @returns class
     */
    $.planactor.indicator = function(element, options){
        /**
         * @static
         */
        var self = this;
        self.element = element;
        self.$element = $(element);
        self.index = ++$.planactor.indicator.manager.index;
        self.isDisplay = false;

        self.$element.data('planactor.indicator', self);
        $.planactor.indicator.manager.add(self.$element.get(0));
        self.IE6 = $.planactor.indicator.manager.IE6;

        /**
         * @memberOf $.planactor.indicator
         * @function
         */
        self.init = function(){
            self.options = $.extend({}, $.planactor.indicator.defaultOptions, options);
            ++$.planactor.indicator.defaultOptions.zIndex;

            self.$wrapper = $('<div/>').addClass('planactor-indicator').addClass(self.options.className || '').css({
                'position'  : 'absolute',
                'display'   : 'none',
                'z-index'   : self.options.zIndex,
                'opacity'   : self.options.opacity
            }).append(self.$indicator = $('<div/>').addClass(self.options.className || '').css({
                'position'  : 'absolute',
                'display'   : 'block'
            })).appendTo(document.body);

            // IE6
            if (true === self.IE6){
                self.$wrapperIframe = $('<iframe/>').attr({
                    'src'   : 'about:blank',
                    'frameborder' : 0
                }).css({
                    'position'  : 'absolute',
                    'display'   : 'none',
                    'z-index'   : self.options.zIndex-1,
                    'opacity'   : 0
                }).appendTo(document.body);
            }

            // 화면 전체 indicator
            if (document === self.$element.get(0) || document.body === self.$element.get(0) || window === self.$element.get(0)){
                self.isFullScreen = true;
                self.element = window, self.$element = $(window);

                self.$wrapper.css({
                    'left'  : 0,
                    'top'   : 0,
                    'width' : self.$element.width(),
                    'height': self.$element.height()
                });
                self.$indicator.css({
                    'width' : self.$element.width(),
                    'height': self.$element.height()
                });
                if (true === self.IE6){
                    self.$wrapperIframe.css({
                        'left'  : 0,
                        'top'   : 0,
                        'width' : self.$element.width(),
                        'height': self.$element.height()
                    });
                }
            }
            // 특정 element indicator
            else {
                self.isFullScreen = false;
            }
            $(window).resize(function(e){
                self.resize();
            });
            $(window).scroll(function(e){
                self.resize();
            });

            if (true === self.options.auto){
                self.show();
            }
        };

        /**
         * 인디케이션 사이즈 재설정
         * @memberof $.planactor.indicator
         * @function
         * @returns {self}
         */
        self.resize = function(){
            if (self.isFullScreen) {
                var $window = $(window);
                self.$indicator.css({
                    'width' : $window.width(),
                    'height': $window.height()
                });

                self.$wrapper.css({
                    'left'  : $window.scrollLeft(),
                    'top'   : $window.scrollTop(),
                    'width' : $window.width(),
                    'height': $window.height()
                });
                if (true === self.IE6){
                    self.$wrapperIframe.css({
                        'width' : $window.width(),
                        'height': $window.height()
                    });
                }
            } else {
                var offset = self.$element.offset() || {left:0,top:0}, width = self.$element.outerWidth(), height = self.$element.outerHeight();
                self.$indicator.css({
                    'width' : width,
                    'height': height
                });
                self.$wrapper.css({
                    'left'  : offset.left,
                    'top'   : offset.top,
                    'width' : width,
                    'height': height
                });
                if (true === self.IE6){
                    self.$wrapperIframe.css({
                        'left'  : offset.left,
                        'top'   : offset.top,
                        'width' : width,
                        'height': height
                    });
                }
            }
            return self;
        };

        /**
         * @memberOf $.planactor.indicator
         * @function
         * @returns {self}
         */
        self.show = function(){
            // 특정 엘리면트 indicator일경우 show 시점에 사이즈 재계산
            // !self.isFullScreen && self.resize();
            self.resize();

            if (planactor.isFunction(self.options.showBefore)) self.options.showBefore.call(self, self.$element.get(0));
            self.$wrapper.show();
            if (planactor.isFunction(self.options.showAfter)) self.options.showAfter.call(self, self.$element.get(0));

            if (true === self.IE6){
                self.$wrapperIframe.show();
            }
            if (planactor.isNumber(self.options.hideDelay) && self.options.hideDelay > 0){
                setTimeout(function(){
                    self.hide();
                }, self.options.hideDelay);
            }

            if (planactor.isString(self.options.hideOn)){
                self.$wrapper.on(self.options.hideOn, function(e){
                    self.hide();
                });
            }
            self.isDisplay = true;
            return self;
        };

        /**
         * @memberOf $.planactor.indicator
         * @function
         * @returns {self}
         */
        self.hide = function(){
            if (planactor.isFunction(self.options.hideBefore)) self.options.hideBefore.call(self, self.$element.get(0));
            self.$wrapper.hide();
            if (planactor.isFunction(self.options.hideAfter)) self.options.hideAfter.call(self, self.$element.get(0));

            if (true === self.IE6){
                self.$wrapperIframe.hide();
            }
            self.isDisplay = false;
            return self;
        };

        /**
         * @memberOf $.planactor.indicator
         * @function
         * @returns {self}
         */
        self.remove = function(){
            $.planactor.indicator.manager.remove(self.$element);
            return self;
        };
        self.init();
    };
    /**
     * @memberof $.planactor.indicator
     * @static
     * @example
$.extend($.planactor.indicator.defaultOptions, {
    className   : 'whiteIndicator',
    opacity     : 0.8,
    zIndex      : 100,
    auto        : true,     // 인디케이터 호출 시 자동 show
    hideDelay   : false,    // 인디케이터 show 이후 자동 hide 되기까지 time 설정 (ms단위)
    showTimer   : 'slow',        // 인디케이터 fadeIn(timer) or 0일 경우 show()
    showBefore  : $.noop,   // 인디케이터 show 전 함수 호출
    showAfter   : $.noop,   // 인티케이터 show 이후 함수 호출
    hideOn      : false,    // 인디케이터 click, mouseover 이벤트 발생 시 hide
    hideTimer   : 'slow',        // 인디케이터 fadeOut(timer) or 0일 경우 hide()
    hideBefore  : $.noop,   // 인디케이터 hide 전 함수 호출
    hideAfter   : $.noop    // 인디케이터 hide 이후 함수 호출
});
     */
    $.planactor.indicator.defaultOptions = {
        className   : 'whiteIndicator',
        opacity     : 0.8,
        zIndex      : 100,
        auto        : true,     // 인디케이터 호출 시 자동 show
        hideDelay   : false,    // 인디케이터 show 이후 자동 hide 되기까지 time 설정 (ms단위)
        showTimer   : 'slow',        // 인디케이터 fadeIn(timer) or 0일 경우 show()
        showBefore  : $.noop,   // 인디케이터 show 전 함수 호출
        showAfter   : $.noop,   // 인티케이터 show 이후 함수 호출
        hideOn      : false,    // 인디케이터 click, mouseover 이벤트 발생 시 hide
        hideTimer   : 'slow',        // 인디케이터 fadeOut(timer) or 0일 경우 hide()
        hideBefore  : $.noop,   // 인디케이터 hide 전 함수 호출
        hideAfter   : $.noop    // 인디케이터 hide 이후 함수 호출
    };
    /**
     * @memberof $.planactor.indicator
     * @namespace $.planactor.indicator.manager
     */
    $.planactor.indicator.manager = {
        index   : 0,
        /**
         * @memberof $.planactor.indicator.manager
         * @type {array}
         * @static
         */
        elements : [],
        /**
         * @memberof $.planactor.indicator.manager
         * @function
         * @param element
         */
        add : function(element){
            this.elements.push(element);
        },
        IE6 : (cssua.ua.ie < 7.0),
        /**
         * element와 매칭된 인디케이터 삭제
         * @memberof $.planactor.indicator.manager
         * @function
         * @param element
         */
        remove : function(element){
            var $element = $(element);
            if ($element.data('planactor.indicator') instanceof $.planactor.indicator){
                var $instance = $element.data('planactor.indicator');
                $instance.$wrapper.remove();
                $instance.$indicator.remove();
                if (this.IE6) $instance.$wrapperIframe.remove();
                $element.data('planactor.indicator', null);
                $instance = null;
                this.elements = this.elements.findAll(function(object){
                    return (object !== $element.get(0));
                });
            }
            $element = null;
        },
        /**
         * 화면내 모든 인디케이터 제거
         * @memberof $.planactor.indicator.manager
         * @function
         */
        clear : function(){
            this.elements.each(function(element){
                $.planactor.indicator.manager.remove(element);
            });
        }
    };
    /**
     * 인디케이터
     * @memberof $.fn
     * @class
     * @param {object} options 옵션 {@link $.planactor.indicator.defaultOptions} 참고
     * @returns $.fn
     * @example
$('#target').planactor_indicator({
    className   : 'bigWhiteIndicator',
    background  : null,
    opacity     : 0.8,
    zIndex      : 100,
    auto        : true,     // 인디케이터 호출 시 자동 show
    hideDelay   : 1000,    // 인디케이터 show 이후 자동 hide 되기까지 time 설정 (ms단위)
    showBefore  : function(){ console.log('showBefore'); },   // 인디케이터 show 전 함수 호출
    showAfter   : function(){ console.log('showAfter'); },   // 인티케이터 show 이후 함수 호출
    hideOn      : false,    // 인디케이터 click, mouseover 이벤트 발생 시 hide
    hideBefore  : function(){ console.log('hideBefore'); },   // 인디케이터 hide 전 함수 호출
    hideAfter   : function(){ console.log('hideAfter'); }    // 인디케이터 hide 이후 함수 호출
});
     */
    $.fn.planactor_indicator = function(options){
        return this.each(function(){
            (new $.planactor.indicator(this, options));
        });
    };

    /**
     * iframe 동적 레이어팝업
     * new $.planactor.iframe(options)
     * @memberof $.planactor
     * @class
     * @param {object} options 옵션 {@link $.planactor.iframe.defaultOptions} 참고
     */
    $.planactor.iframe = function(options){
        this.isDisplay = false;
        this.setOptions(options);

        this.$window = $(window);
        this.setOptions(options);

        // 화면 중앙으로 위치 fix
        this.$window.resize(function(e){
            if (true === this.isDisplay){
                this.positionCenter();
            }
        }.call(this));
        this.show();
    };
    $.planactor.iframe.instance = null;
    $.planactor.iframe.getInstance = function(options){
        if (null === $.planactor.iframe.instance){
            $.planactor.iframe.instance = new $.planactor.iframe(options);
        } else {
            $.planactor.iframe.instance.setOptions($.extend($.planactor.iframe.instance.options, options));
        }
        return $.planactor.iframe.instance;
    };
    /**
     * @memberof $.planactor.iframe
     * @example
$.extend($.planactor.iframe.defaultOptions, {
    src         : 'about:blank',
    width       : 600,
    height      : 500,
    opacity     : 0.8,
    zIndex      : 100,
    indicatorClassName : 'bigBlackIndicator',
    scrolling   : 'no',
    showBefore  : $.noop,
    showAfter   : $.noop,
    hideBefore  : $.noop,
    hideAfter   : $.noop,
    hideOn      : 'click'     // click
});
     */
    $.planactor.iframe.defaultOptions = {
        src         : 'about:blank',
        width       : 600,
        height      : 500,
        opacity     : 0.8,
        zIndex      : 100,
        indicatorClassName : 'bigBlackIndicator',
        scrolling   : 'no',
        showBefore  : $.noop,
        showAfter   : $.noop,
        hideBefore  : $.noop,
        hideAfter   : $.noop,
        hideOn      : 'click'     // click
    };
    $.planactor.iframe.prototype = {
        setOptions : function(options){
            this.options = $.extend({}, $.planactor.iframe.defaultOptions, options);
        },
        positionCenter : function(){
            var width = this.$window.width(), height = this.$window.height();
            var offset = this.$iframe.offset();
            this.$iframe.css({
                'left'  : (('100%' == this.options.width) ? 0 : (width/2 - this.options.width/2)) + this.$window.scrollLeft(),
                'top'   : (('100%' == this.options.width) ? 0 : (height/2 - this.options.height/2)) + this.$window.scrollTop()
            });
        },
        create : function(){
            this.$iframe = $('<iframe/>')
                .addClass('planactor-iframe')
                .attr('src', 'about:blank')
                .attr('frameborder', 0)
                .attr('scrolling', this.options.scrolling)
                .css({
                    'display'   : 'none',
                    'width'     : this.options.width,
                    'height'    : this.options.height,
                    'z-index'   : this.options.zIndex+1
                }).appendTo(document.body);

            this.$wrapper = $('<div/>')
                .addClass('planactor-iframe-wrapper')
                .addClass(this.options.indicatorClassName)
                .css({
                    'display'   : 'none',
                    'opacity': this.options.opacity,
                    'z-index':this.options.zIndex
                })
                .appendTo(document.body);
        },
        /**
         * iframe delete
         * @memberof $.planactor.iframe
         * @function
         * @returns {$.planactor.iframe}
         */
        remove : function(){
            this.$iframe.attr('src', 'about:blank');
            this.$iframe.remove();
            this.$wrapper.remove();
            this.$iframe = null;
            this.$wrapper = null;
            $.planactor.iframe.instance = null;
        },
        /**
         * iframe show
         * @memberof $.planactor.iframe
         * @returns {$.planactor.iframe}
         */
        show : function(){
            var self = this;
            // iframe element 생성
            this.create();
            this.positionCenter();

            if (planactor.isFunction(this.options.showBefore)) this.options.showBefore.call(this);

            this.$wrapper.show();
            this.$iframe.one('load',function(){
                self.$iframe.show();
                if (planactor.isString(self.options.hideOn)){
                    self.$wrapper.one(self.options.hideOn, function(e){
                        self.hide();
                    });
                }
                if (planactor.isFunction(self.options.showAfter)) self.options.showAfter.call(self);
                self.isDisplay = true;
            });

            // iframe 경로 설정
            this.$iframe.attr('src', this.options.src);
            return this;
        },
        /**
         * iframe hidden
         * @memberof $.planactor.iframe
         * @returns {$.planactor.iframe}
         */
        hide : function(){
            if (planactor.isFunction(this.options.hideBefore)) this.options.hideBefore.call(this);
            this.$iframe.hide();
            this.$wrapper.hide();
            // hide 등록된 이벤트 제거
            this.$wrapper.off(this.options.hideOn);
            this.remove();
            this.isDisplay = false;
            if (planactor.isFunction(this.options.hideAfter)) this.options.hideAfter.call(this);
            return this;
        }
    };

    /**
     * 셀렉트 선택 시 콤보기능으로 이용형태는 {@link $.fn.planactor_selectbox} 참고
     * @memberof $.planactor
     * @class
     * @param {element|string} element 선택 selectbox (1차)
     * @param {element|string} target 변경될 selectbox (2차)
     * @param {object} options 옵션 {@link $.planactor.selectbox.defaultOptions} 참고
     */
    $.planactor.selectbox = function(element, target, options){
        var self = this;
        self.$element = $(element).eq(0);
        self.element = self.$element.get(0);

        if (!options && $.isPlainObject(target)){
            options = target;
        }
        self.options = $.extend({
            url : '',
            data : {},
            name : null, // key field (POST 변수명을 변경함)
            dataType : 'json',
            done : function(data, textStatus, jqXHR){},
            fail : function(jqXHR, textStatus, errorThrown){},
            // data|jqXHR, textStatus, jqXHR|errorThrown
            always : function(data, textStatus, jqXHR){},
            title : null,
            className : 'blackIndicator',
            background : null,
            opacity : 0.8,
            auto : true,
            zIndex : 100
        }, $.planactor.selectbox.defaultOptions, options);

        if (target && !$.isPlainObject(target)){
            self.$target = $(target);
            self.$target.planactor_indicator({
                className   : self.options.className,
                background  : self.options.background,
                hideTimer   : 0,
                opacity     : self.options.opacity,
                zIndex      : self.options.zIndex,
                effect      : ['fade',{},300],
                auto        : false
            });
        }
        self.$element.data('planactor.selectbox', self);

        // target element 초기화
        self.initSelectOptions()
            .$element.on('change', function(){
                // show indicator
                self.$target && self.$target.data('planactor.indicator').show();
                var data = $.extend({}, self.options.data, $(this).serializeJson());
                if (planactor.isNotEmpty(self.options.name)){
                    data[self.options.name] = data[$(this).attr('id')];
                }
                $.post(self.options.url, data, function(data, textStatus, jqXHR){
                    var done = $.proxy(self.options.done, self);
                    done(data, textStatus, jqXHR);
                    // 자동 optons 생성
                    self.options.auto && self.setSelectOptions(data);
                }, self.options.dataType)
                    .fail(self.options.fail)
                    .always(function(data, textStatus, jqXHR){
                        self.options.always(data, textStatus, jqXHR);
                        // hide indicator
                        self.$target && self.$target.data('planactor.indicator').hide();
                    });
            });
    };
    $.planactor.selectbox.prototype = {
        initSelectOptions : function(){
            if (this.$target){
                var options = this.$target.find('option');
                if (0 == options.length){
                    this.$target.find('option').remove();
                    if (!planactor.isEmpty(this.options.title)){
                        this.$target.append('<option value>'+this.options.title+'</option>');
                    }
                }
            }
            return this;
        },
        setSelectOptions : function(data){
            if (this.$target){
                this.$target.find('option').remove();
                if (!planactor.isEmpty(this.options.title)){
                    this.$target.append('<option value="">'+this.options.title+'</option>');
                }
                if (planactor.isArray(data)){
                    $.each(data, $.proxy(function(i, obj){
                        this.$target.append('<option value="'+obj.value+'">'+obj.label+'</option>');
                    },this));
                }else{
                    $.each(data, $.proxy(function(k, v){
                        this.$target.append('<option value="'+k+'">'+v+'</option>');
                    },this));
                }
            }
            return this;
        }
    };
    /**
     * @memberof $.planactor.selectbox
     * @example
$.extend($.planactor.selectbox.defaultOptions, {
    url : 'about:blank',
    data : {},
    name : null, // key field (POST 변수명을 변경함)
    dataType : 'json',
    done : function(data, textStatus, jqXHR){},
    fail : function(jqXHR, textStatus, errorThrown){},
    // data|jqXHR, textStatus, jqXHR|errorThrown
    always : function(data, textStatus, jqXHR){},
    title : null,
    className : 'blackIndicator',
    background : null,
    opacity : 0.8,
    auto : true,
    zIndex : 100
});
     */
    $.planactor.selectbox.defaultOptions = {
        url : 'about:blank',
        data : {},
        name : null, // key field (POST 변수명을 변경함)
        dataType : 'json',
        done : function(data, textStatus, jqXHR){},
        fail : function(jqXHR, textStatus, errorThrown){},
        // data|jqXHR, textStatus, jqXHR|errorThrown
        always : function(data, textStatus, jqXHR){},
        title : null,
        className : 'blackIndicator',
        background : null,
        opacity : 0.8,
        auto : true,
        zIndex : 100
    };
    /**
     * 다중 셀렉트박스 선택 시 콤보기능
     * @memberof $.fn
     * @class
     * @param {element|string} target 변경될 selectbox
     * @param {object} options 옵션 {@link $.planactor.selectbox.defaultOptions} 참고
     * @extends $.planactor.selectbox
     * @example
$("#supporters_member_ID").planactor_selectbox("#site_ID", {
    url : "/manager/ajax/site-list",
    title : "::업체선택::"
});
     * @example
// 서버단 Ajax Json 반환형태
// object 형태
{"ID":"name","ID2":"name"}
// array 형태
[{"label":"label1","value":"value1"},... 반복]
     */
    $.fn.planactor_selectbox = function(target, options){
        return this.each(function(){
            (new $.planactor.selectbox(this, target, options));
        });
    };

/**
 * 비동기 로직 동기화 처리 함수 (비동기적 스크립트에 대해 순차적 실행을 원할 때 사용)
        var $sync = new $.planactor.sync({
            'multi' : 1,
            'create' : function(){
                console.log('create');
            },
            'complete' : function(){
                console.log('complete');
                }
            });
            for(var i=0; i<100; ++i){
                $sync.add(function(callback, index){
                    console.log(index);
                    callback();
                });
                ---
                $sync.add($.proxy(function(i, callback, index)){
                    callback();
                },null,i))
            }
            $sync.start();
 * @param {Object} options
 */
    $.planactor.sync = function(options){
        var self = this;
        this.queue = [];
        this.index = 0;
        this.runCount = 0;
        this.options = $.extend({
            'create'    : $.noop,
            'complete'  : $.noop,
            'multi'     : 1
        }, options);
    };
    $.planactor.sync.prototype = {
        /**
         * 비동기 로직
         * @param {Object} func 함수 또는 배열함수
         */
        add : function(func){
            if (planactor.isFunction(func)) this.queue.push(func);
            else if (planactor.isArray(func)) this.queue.concat(func);
            else throw Error('sync.add(func) param error!');
            return this;
        },
        setOption : function(options){
            this.options = $.extend({}, this.options, options);
            return this;
        },
        count : function(){
            return this.queue.length;
        },
        callback : function(){
            (this.runCount > 0) && --this.runCount;
            if (this.count() > 0){
                ++this.runCount;
                var func = this.queue.shift();
                if (planactor.isFunction(func)){
                    $.proxy(func,this)($.proxy(this.callback,this), this.index++);
                }
            }
            // 처리할 함수가 없을 경우 complete 호출
            else{
                (0 == this.runCount) && this.options.complete();
            }
            return this;
        },
        start : function(){
            this.options.create();
            if (this.count() > 0){
                for(var i=0; (i < this.options.multi) && (this.count() > 0); ++i){
                    ++this.runCount;
                    var func = this.queue.shift();
                    if (planactor.isFunction(func)){
                        $.proxy(func,this)($.proxy(this.callback,this), this.index++);
                    }
                }
            }
        },
        clear : function(){
            this.queue = [];
            this.index = 0;
            this.runCount = 0;
            this.options = {
                'create'    : $.noop,
                'complete'  : $.noop,
                'multi'     : 1
            };
        }
    };

})(jQuery);

(function($, window, document){
    var $window = $(window);

    $.fn.planactor_preload = function(options){
        var elements = this;
        var options = $.extend({
            'container' : window,
            'threshold' : 100,
            'event'     : 'scroll',
            'effect'    : 'fade',
            'dataAttr'  : 'original'
        }, options);
        var $container = (options.container === undefined || options.container === window) ? $window : $(options.container);

        function update(){

        };

        this.each(function(){
            var self = this;
            var $self = (self);
            self.loaded = false;

            // $self.one('');

        });

        return this;
    };

    /**
     * element가 화면 또는 지정된 container 안에 들어와 있는지 확인
     */
    $.planactor.viewport = function(element, options){
        return  $.planactor.viewport.left(element, options) &&
                $.planactor.viewport.right(element, options) &&
                $.planactor.viewport.top(element, options) &&
                $.planactor.viewport.bottom(element, options);
    };
    /**
     * element right가 container left 오른쪽에 위치
     * @param {Object} element
     * @param {Object} options
     */
    $.planactor.viewport.left = function(element, options){
        var $element = $(element);
        options = $.extend({
            threshold : 100
        }, options);

        var leftContainer;
        if (options.container === undefined || options.container === window) {
            leftContainer = $window.scrollLeft();
        } else {
            var $container = $(options.container);
            leftContainer = $container.offset().left;
        }
        return leftContainer <= $element.offset().left + $element.width() + options.threshold;
    };
    /**
     * element left가 container right 왼쪽에 위치
     * @param {Object} element
     * @param {Object} options
     */
    $.planactor.viewport.right = function(element, options){
        var $element = $(element);
        options = $.extend({
            threshold : 100
        }, options);

        var rightContainer;
        if (options.container === undefined || options.container === window) {
            rightContainer = $window.width() + $window.scrollLeft();
        } else {
            var $container = $(options.container);
            rightContainer = $container.offset().left + $container.width();
        }
        return rightContainer >= $element.offset().left - options.threshold;
    };
    /**
     * element bottom이 container top 아래에 위치
     * @param {Object} element
     * @param {Object} options
     */
    $.planactor.viewport.top = function(element, options){
        var $element = $(element);
        options = $.extend({
            threshold : 100
        }, options);

        var topContainer;
        if (options.container === undefined || options.container === window) {
            topContainer = $window.scrollTop();
        } else {
            topContainer = $(options.container).offset().top;
        }
        return topContainer <= $element.offset().top + $element.height() + options.threshold;
    };
    /**
     * element top이 container bottom 위쪽에 위치
     * @param {Object} element
     * @param {Object} options
     */
    $.planactor.viewport.bottom = function(element, options){
        var $element = $(element);
        options = $.extend({
            threshold : 100
        }, options);

        var bottomContainer;
        if (options.container === undefined || options.container === window) {
            bottomContainer = (window.innerHeight ? window.innerHeight : $window.height()) + $window.scrollTop();
        } else {
            var $container = $(options.container);
            bottomContainer = $container.offset().top + $container.height();
        }
        return bottomContainer >= $element.offset().top - options.threshold;
    };
})(jQuery, window, document);
